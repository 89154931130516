<template>
    <v-container fluid>
		<v-row justify="center" class="ma-0">
			<v-col cols="12" sm="3" md="2" class="pa-0">
                <div class="mb-8 primary--text text-center">Membres sans équipe</div>
                <Container group-name="equipes" @drop="onDropSans($event, 'sans', null)" :get-child-payload="index => getChildPayloadSans(index,'sans')" class="zone_sans_equipe">
                    <Draggable v-for="membre in liste_sans_equipe_filtree.membres" :key="membre.id">
                        <v-card  flat class="mb-2" elevation="1" :color="membre.enDispo || !membre.estActif ? 'grey lighten-1': 'grey lighten-5'" max-width="350">
                            <v-list-item two-line dense>
                                <v-list-item-avatar class="my-0" :color="membre.enDispo || !membre.estActif ? 'blue-grey lighten-5': 'blue-grey lighten-3'">
                                    <img v-if="membre.photo" :src="getPhoto(membre.photo)">
                                    <span v-else :class="membre.enDispo || !membre.estActif ? 'grey--text':'white--text'" class="text-h6 font-weight-light">{{premiereLettreMaj(membre.prenom)+premiereLettreMaj(membre.nom)}}</span>
                                </v-list-item-avatar>
                                <v-list-item-content class="py-0">
                                    <v-list-item-title :class="membre.enDispo || !membre.estActif ? 'grey--text text--lighten-3': ''">{{ membre.nom + ' ' +  membre.prenom}}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        <template v-for="aptitude in membre.aptitudes">
                                            <v-chip v-if="aptitude.id == 3" :key="aptitude.id" label small color="lime darken-3" text-color="white">{{ aptitude.nom }}</v-chip>
                                            <v-chip v-if="aptitude.id == 10" :key="aptitude.id" label small color="red darken-3" text-color="white">{{ aptitude.nom }}</v-chip>
                                        </template>
                                        <v-chip v-if="membre.enDispo" label small color="brown lighten-4">En dispo</v-chip>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card>
                    </Draggable>
                </Container>
			</v-col>
            <v-col cols="12" sm="9" md="10" class="pa-0 pl-4">
                <v-row class="ma-0">
                    <div class="mb-4 primary--text text-center flex-grow-1">Equipes du centre</div>
                    <v-radio-group class="ma-1 mr-8" row dense hide-details mandatory v-model="filtre_equipe">
                        <v-radio label="Toutes" value="toutes"></v-radio>
                        <v-radio label="SPP" value="spp"></v-radio>
                        <v-radio label="SPV" value="spv"></v-radio>
                    </v-radio-group>
                    <v-tooltip left v-if="acces_autorise('modification equipes')">
                        <template v-slot:activator="{ on }">
                            <v-btn icon color="primary" dark v-on="on" @click="onClickAjoutEquipe">
                                <v-icon>mdi-account-multiple-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Ajouter une équipe</span>
                    </v-tooltip>
                    <v-tooltip left v-if="acces_autorise(['modification equipes'])">
                        <template v-slot:activator="{ on }">
                            <v-btn class="ml-6" icon color="primary" dark v-on="on" @click="onClickUpdateEquipe" :loading="chargement_update">
                                <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                        </template>
                        <span>Sauvegarder les modifications</span>
                    </v-tooltip>
                </v-row>
				<v-row class="ma-0 mt-2" :class="!affichagePortable ? 'zone_equipes' : ''" justify="start">
                    <template v-if="listes_equipes_filtree.length == 0">
                        <v-col cols="12" class="pa-0">
                            <div class="text-center mt-12"><v-icon x-large>mdi-emoticon-sad-outline</v-icon></div>
                            <div class="text-center font-italic mt-2">Aucune équipe existante</div>
                        </v-col>
                    </template>
                    <template v-else v-for="(equipe, cle, index) in listes_equipes_filtree">
                        <v-card outlined class="mt-2 mb-6" :class="!affichagePortable ? 'mr-6' :''" :key="index" min-width="300" :max-width="!affichagePortable ? '300' :''" color="blue-grey lighten-5">
                            <v-toolbar color="deep-purple lighten-1" dark flat dense>
                                <v-toolbar-title>{{ equipe.nom }}
                                    <v-chip label small light :color="equipe.est_spp ? 'blue lighten-4':'light-green lighten-4'" class="ml-2">{{equipe.est_spp ? 'SPP' : 'SPV'}}</v-chip>
                                </v-toolbar-title>
                                <div class="flex-grow-1"></div>
                                <v-chip class="ma-2" color="deep-purple lighten-3">{{ equipe.membres.length }}</v-chip>
                                <v-btn text icon v-if="acces_autorise(['modification equipes']) && equipe.membres.length == 0" @click="onClickSupprEquipe(equipe)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </v-toolbar>
                            <div class="text-overline ma-2 deep-purple--text font-weight-bold">Chef</div>
                            <Container group-name="equipes" @drop="onDrop($event, cle, 'Chef d\'équipe')" style="min-height: 80px" :get-child-payload="index => getChildPayload(index,cle, getChefEquipe(equipe.membres))">
                            <!-- <div > -->
                                <Draggable v-for="(membre, index) in getChefEquipe(equipe.membres)" :key="index" >
                                    <v-card flat class="mb-3 mx-2" outlined :color="membre.enDispo || !membre.estActif ? 'grey lighten-1': 'grey lighten-5'">
                                        <v-list-item two-line dense>
                                            <v-list-item-avatar class="my-0" :color="membre.enDispo || !membre.estActif ? 'blue-grey lighten-5': 'blue-grey lighten-3'">
                                                <img v-if="membre.photo" :src="getPhoto(membre.photo)">
                                                <span v-else :class="membre.enDispo || !membre.estActif ? 'grey--text':'white--text'" class="text-h6 font-weight-light">{{premiereLettreMaj(membre.prenom)+premiereLettreMaj(membre.nom)}}</span>
                                            </v-list-item-avatar>
                                            <v-list-item-content class="py-0">
                                                <v-list-item-title :class="membre.enDispo || !membre.estActif ? 'grey--text text--lighten-3': ''">{{ membre.nom + ' ' +  membre.prenom}}</v-list-item-title>
                                                <v-list-item-subtitle>
                                                    <template v-for="aptitude in membre.aptitudes">
                                                        <v-chip v-if="aptitude.id == 3" :key="aptitude.id" label small color="lime darken-3" text-color="white">{{ aptitude.nom }}</v-chip>
                                                        <v-chip v-if="aptitude.id == 10" :key="aptitude.id" label small color="red darken-3" text-color="white">{{ aptitude.nom }}</v-chip>
                                                    </template>
                                                    <v-chip v-if="membre.enDispo" label small color="brown lighten-4">En dispo</v-chip>
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-card>
                                </Draggable>
                            <!-- </div> -->
                            </Container>
                            <v-divider></v-divider>
                            <div class="text-overline ma-2 deep-purple--text font-weight-bold">Adjoints</div>
                            <Container group-name="equipes" @drop="onDrop($event, cle, 'Adjoint équipe')" style="min-height: 170px" :get-child-payload="index => getChildPayload(index,cle, getAdjointEquipe(equipe.membres))">
                            <!-- <div style="min-height: 170px"> -->
                                <Draggable v-for="(membre, index) in getAdjointEquipe(equipe.membres)" :key="index">
                                    <v-card  flat class="mb-3 mx-2" outlined :color="membre.enDispo || !membre.estActif ? 'grey lighten-1': 'grey lighten-5'">
                                        <v-list-item two-line dense>
                                            <v-list-item-avatar class="my-0" :color="membre.enDispo || !membre.estActif ? 'blue-grey lighten-5': 'blue-grey lighten-3'">
                                                <img v-if="membre.photo" :src="getPhoto(membre.photo)">
                                                <span v-else :class="membre.enDispo || !membre.estActif ? 'grey--text':'white--text'" class="text-h6 font-weight-light">{{premiereLettreMaj(membre.prenom)+premiereLettreMaj(membre.nom)}}</span>
                                            </v-list-item-avatar>
                                            <v-list-item-content class="py-0">
                                                <v-list-item-title :class="membre.enDispo || !membre.estActif ? 'grey--text text--lighten-3': ''">{{ membre.nom + ' ' +  membre.prenom}}</v-list-item-title>
                                                <v-list-item-subtitle>
                                                    <template v-for="aptitude in membre.aptitudes">
                                                        <v-chip v-if="aptitude.id == 3" :key="aptitude.id" label small color="lime darken-3" text-color="white">{{ aptitude.nom }}</v-chip>
                                                        <v-chip v-if="aptitude.id == 10" :key="aptitude.id" label small color="red darken-3" text-color="white">{{ aptitude.nom }}</v-chip>
                                                    </template>
                                                    <v-chip v-if="membre.enDispo" label small color="brown lighten-4">En dispo</v-chip>
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-card>
                                </Draggable>
                            <!-- </div> -->
                            </Container>
                            <v-divider></v-divider>
                            <div class="text-overline ma-2 deep-purple--text font-weight-bold">Membres</div>
                            <Container group-name="equipes" @drop="onDrop($event, cle, null)" style="min-height: 300px" :get-child-payload="index => getChildPayload(index,cle, getMembreEquipe(equipe.membres))">
                            <!-- <div style="min-height: 300px"> -->
                                <Draggable v-for="(membre, index) in getMembreEquipe(equipe.membres)" :key="index">
                                    <v-card flat class="mb-3 mx-2" outlined :color="membre.enDispo || !membre.estActif ? 'grey lighten-1': 'grey lighten-5'">
                                        <v-list-item two-line dense>
                                            <v-list-item-avatar class="my-0" :color="membre.enDispo || !membre.estActif ? 'blue-grey lighten-5': 'blue-grey lighten-3'">
                                                <img v-if="membre.photo" :src="getPhoto(membre.photo)">
                                                <span v-else :class="membre.enDispo || !membre.estActif ? 'grey--text':'white--text'" class="text-h6 font-weight-light">{{premiereLettreMaj(membre.prenom)+premiereLettreMaj(membre.nom)}}</span>
                                            </v-list-item-avatar>
                                            <v-list-item-content class="py-0">
                                                <v-list-item-title :class="membre.enDispo || !membre.estActif ? 'grey--text text--lighten-3': ''">{{ membre.nom + ' ' +  membre.prenom}}</v-list-item-title>
                                                <v-list-item-subtitle>
                                                    <template v-for="aptitude in membre.aptitudes">
                                                        <v-chip v-if="aptitude.id == 3" :key="aptitude.id" label small color="lime darken-3" text-color="white">{{ aptitude.nom }}</v-chip>
                                                        <v-chip v-if="aptitude.id == 10" :key="aptitude.id" label small color="red darken-3" text-color="white">{{ aptitude.nom }}</v-chip>
                                                    </template>
                                                    <v-chip v-if="membre.enDispo" label small color="brown lighten-4">En dispo</v-chip>
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-card>
                                </Draggable>
                            <!-- </div> -->
                            </Container>
                        </v-card>
                    </template>
				</v-row>
			</v-col>
		</v-row>

        <!-- dialogue confirmation -->
        <v-dialog v-model="openDialogueConfirmation" max-width="290">
            <v-card>
                <v-toolbar dark flat dense color="primary">
                    <v-toolbar-title>
                        <v-card-title>Suppression</v-card-title>
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-3">Supprimer {{ dialogue_confirmation.texte }} ?</v-card-text>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="blue-grey darken-1" text @click="annuleConfirmation">Annuler</v-btn>
                    <v-btn color="blue-grey darken-1" text @click="validSuppression" :loading="chargement_confirmation">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- dialogue ajout équipe -->
        <v-dialog v-model="openDialogueAjout" max-width="290">
            <v-card>
                <v-toolbar dark flat dense color="primary">
                    <v-toolbar-title>
                        <v-card-title>Ajout d'une équipe</v-card-title>
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-3">
                    <v-row class="ma-0">
                        <v-text-field v-model.trim="$v.dialogue_ajout_equipe.nom.$model" label="Nom" :error-messages="erreursAjout"></v-text-field>
                    </v-row>
                    <v-row class="0">
                        <v-radio-group hide-details mandatory v-model="dialogue_ajout_equipe.est_spp">
                            <v-radio label="SPV" :value=false></v-radio>
                            <v-radio label="SPP" :value=true></v-radio>
                        </v-radio-group>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="blue-grey darken-1" text @click="annuleAjout">Annuler</v-btn>
                    <v-btn color="blue-grey darken-1" text @click="ajoutEquipe" :loading="chargement_ajout" :disabled="estFormulaireAjoutInvalid">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
	</v-container>
</template>

<script>
    import axios from 'axios'
    import { required } from 'vuelidate/lib/validators'
    import { Container, Draggable } from 'vue-smooth-dnd'
    export default {
        components: {
            //  draggable,
            Container,
            Draggable
        },
        created() {
            this.$store.commit('majTitrePage', 'Equipes')
        },
        mounted() {
            //	initialisation du centre
            this.centre_selectionne = this.$store.getters.membreCourant.centre.id
            this.$store.dispatch('getAnnuaire', this.centre_selectionne)
                .then(() => {
                    this.$store.dispatch('getEquipes', this.centre_selectionne).then( () => this.init_listes_equipes())
                })
			    //.then( () => this.init_listes_equipes())
        },
        data() {
			return {
                chargement_ajout: false,
                chargement_confirmation: false,
                chargement_update: false,
                centre_selectionne: null,
                dialogue_confirmation: {
                    texte: '',
                    id_equipe: null
                },
                dialogue_ajout_equipe: {
                    nom: '',
                    est_spp: false,
                    id_centre: null
                },
                filtre_equipe: 'spv',
                listes_equipes: [],
                liste_sans_equipe: {
                    id: null,
                    membres: [],
                    nom: 'sans'
                },
                liste_sans_equipe_filtree: {
                    id: null,
                    membres: [],
                    nom: 'sans'
                },
                openDialogueAjout: false,
                openDialogueConfirmation: false
            }
		},
		computed: {
            affichagePortable() {
				return this.$vuetify.breakpoint.name == 'xs'
            },
            centre_courant() {
				return this.$store.getters.membreCourant.centre
            },
            erreursAjout() {
                const errors = []
                if (!this.$v.dialogue_ajout_equipe.nom.$dirty) return errors
                !this.$v.dialogue_ajout_equipe.nom.required && errors.push('Le nom est obligatoire')

                if(this.equipes.find(equipe => equipe.nom == this.dialogue_ajout_equipe.nom && equipe.est_spp == this.dialogue_ajout_equipe.est_spp) != undefined) {
                    errors.push('Ce nom existe déjà')
                }

                return errors
            },
            estFormulaireAjoutInvalid() {
                const existe_deja = this.equipes.find(equipe => equipe.nom == this.dialogue_ajout_equipe.nom && equipe.est_spp == this.dialogue_ajout_equipe.est_spp)
                return this.$v.dialogue_ajout_equipe.$invalid || existe_deja != undefined
            },
			membres() {
				return this.$store.getters.annuaire
            },
            equipes() {
                return this.$store.getters.equipes_centre
                //return this.$store.getters.membreCourant.centre.equipes
            },
            listes_equipes_filtree() {
                let liste = this.listes_equipes
                if(this.filtre_equipe == 'spv') {
                    liste = liste.filter(equipe => equipe.est_spp == false)
                }
                else if(this.filtre_equipe == 'spp') {
                    liste = liste.filter(equipe => equipe.est_spp == true)
                }
                return liste
            },
            // liste_sans_equipe_filtree() {
                
            //     const liste_membres = this.liste_sans_equipe.membres
            //     let liste = []
            //     if(this.filtre_equipe == 'spv') {
            //         liste = liste_membres.filter(membre => membre.statut == 0)
            //     }
            //     else if(this.filtre_equipe == 'spp') {
            //         liste = liste_membres.filter(membre => membre.statut == 1)
            //     }
            //     return {
            //         id: null,
            //         membres: liste,
            //         nom: 'sans'
            //     }
            // }
		},
		methods: {
            acces_autorise(permissions_requises, acces_admin = true) {
                // on vérifie si l'utilisateur a les droits
                //  "permissions_requises" est un tableau de permissions.
                //  return true si l'utilisateur possede l'une des permissions du tableau

                // "acces_admin"  donne ou non l'autorisation à l'admin
                
                let permission_trouvee = false

                if(permissions_requises.length > 0 && this.$store.getters.membreCourant) {
                
                    const roles_utilisateur = this.$store.getters.membreCourant.roles
                    const permissions_utilisateur = this.$store.getters.membreCourant.permissions

                    const estSuperAdmin = roles_utilisateur.find(role => role.name == 'Super admin')
                    

                    //	si super admin on autorise tout
                    if (estSuperAdmin && acces_admin) {
                        permission_trouvee = true
                    }
                    else {
                        //	on cherche si la permission demandée se trouve dans un des roles de l'utilisateur
                        roles_utilisateur.forEach(role => {
                            role.permissions.forEach(permission => {
                                    if( permissions_requises.includes(permission.name) ) {
                                        permission_trouvee = true
                                    }
                                })
                        })
                    

                        // si la permission n'a pas déjà été trouvée, on cherche si elle est dans les permissions directes
                        if (!permission_trouvee) {
                            
                            permissions_utilisateur.forEach(permission => {
                                if( permissions_requises.includes(permission.name) ) {
                                    permission_trouvee = true
                                }
                            })
                        }
                    }
                }

                //	on autorise si pas de permission ou si une permission et que la permission est trouvée
                return permissions_requises.length == 0 || (permissions_requises.length > 0 && permission_trouvee)
			},
            ajoutEquipe() {
                //  on vérifie que le formulaire est valide
                if( !this.$v.dialogue_ajout_equipe.$invalid) {
                    this.chargement_ajout = true
                    axios.post('api/centre/equipes/ajout', { 
                        id_membre: this.$store.getters.membreCourant.id,
                        id_centre: this.dialogue_ajout_equipe.id_centre,
                        nom: this.dialogue_ajout_equipe.nom,
                        est_spp: this.dialogue_ajout_equipe.est_spp
					})
                    .then((reponse) => {
                        this.snackbar('success',reponse.data.resultat);
                        this.$store.dispatch('getEquipes', this.centre_selectionne)
                            .then(() => {
                                this.$store.dispatch('getAnnuaire', this.centre_selectionne)
                            })
                            .then( () => this.init_listes_equipes())
                    })
                    .catch((error) => {
                        this.snackbar('error', 'Erreur lors de l\'ajout');
                    })
                    .then(() => {
						this.chargement_ajout = false
						this.annuleAjout()
                    })
                }
            },
            annuleAjout() {
                this.dialogue_ajout_equipe.nom = ''
                this.dialogue_ajout_equipe.est_spp = false
                this.dialogue_ajout_equipe.id_centre = null
                this.openDialogueAjout = false
            },
            annuleConfirmation() {
                this.dialogue_confirmation.texte = ''
                this.dialogue_confirmation.id_equipe = null
                this.openDialogueConfirmation = false
            },
            applicationDrag(liste, dragResult, role) {
                //  cette fonction est appelée pour chaque liste lorsqu'un élément est déplacé
                
                const { removedIndex, addedIndex, payload } = dragResult
                                 
                //  si pour la liste concernée, il n'y a ni ajout ni retrai on retourne la liste telle quelle
                if (removedIndex === null && addedIndex === null) return liste
                
                const result = [...liste]

                let membre = payload
                
                //  on retire l'élément de la liste source
                if (removedIndex !== null) {

                    //  on cherche l'index correspondant au membre car le removedIndex est celui de la sous-liste
                    const index_membre = liste.findIndex( membre => membre.id == payload.id)
                    membre = result.splice(index_membre, 1)[0]

                    //console.log('suppression de '+dragResult.payload.prenom + ' ' + dragResult.payload.nom)
                    //membre = result.splice(removedIndex, 1)[0]
                }

                //  on ajoute l'élément dans la liste destination
                if (addedIndex !== null) {
                    console.log('ajout de '+dragResult.payload.prenom + ' ' + dragResult.payload.nom+ ' => ' + role)
                    membre.role = role
                    //console.log('payload', payload)
                    result.splice(addedIndex, 0, membre)
                }

                //console.log('resultat', result)

               return result
            },
            getAdjointEquipe(liste) {
               return liste.filter( membre => membre.role == "Adjoint équipe")
            },
            getChefEquipe(liste) {
                return liste.filter( membre => membre.role  == "Chef d'équipe")
            },
            getChildPayload(index ,cle, liste_membre) {
                // index: index du membre dans la liste chef/adjoint/membre
               return liste_membre[index]
            },
            getChildPayloadSans(index, cle) {
                const payload = {
                    ...this.liste_sans_equipe_filtree.membres[index],
                    role_destinataire: null
                }
                
                return this.liste_sans_equipe_filtree.membres[index]
            },
            getMembreEquipe(liste) {
                return liste.filter( membre => membre.role == null)
            },
            getPhoto(chemin){
				return "/storage/"+chemin;
            },
            init_listes_equipes() {
                let liste = []
                this.equipes.forEach(equipe => {
                    const liste_equipe = this.membres.filter( membre => membre.id_equipe == equipe.id) 
                    //console.log(liste_equipe)
                    // restructuration de la liste des membres
                    const liste_membre = []
                    liste_equipe.forEach(membre => {
                        const nom_role = membre.roles.find(role => role.name == "Adjoint équipe" || role.name == "Chef d'équipe")
                        
                        liste_membre.push({
                            id: membre.id,
                            estActif: membre.estActif,
                            enDispo: membre.user.en_dispo,
                            id_equipe: membre.id_equipe,
                            photo: membre.user.photo,
                            nom: membre.user.nom,
                            prenom: membre.user.prenom,
                            statut: membre.statut,
                            role: nom_role != undefined ? nom_role.name : null,
                            aptitudes: membre.user.aptitudes
                        })
                    })
                    
                    liste.push({ 
                        id: equipe.id,
                        nom: equipe.nom,
                        est_spp: equipe.est_spp,
                        membres: liste_membre
                    })
                   
                })
                this.listes_equipes = liste


                //  on ajoute les membres sans équipes
                //liste['sans'] = this.membres.filter( membre => membre.id_equipe == null)

                const liste_sans = this.membres.filter( membre => membre.id_equipe == null)
                // restructuration de la liste des membres
                const liste_sans_new = []
                liste_sans.forEach(membre => {
                        liste_sans_new.push({
                            id: membre.id,
                            estActif: membre.estActif,
                            enDispo: membre.user.en_dispo,
                            id_equipe: membre.id_equipe,
                            photo: membre.user.photo,
                            nom: membre.user.nom,
                            prenom: membre.user.prenom,
                            statut: membre.statut,
                            role: null,
                            aptitudes: membre.user.aptitudes
                        })
                    })
                this.liste_sans_equipe = { 
                    id: null,
                    nom: 'sans',
                    membres: liste_sans_new
                }
                
            },
            onClickAjoutEquipe() {
                this.dialogue_ajout_equipe.id_centre = this.centre_selectionne
                this.openDialogueAjout = true
            },
            onClickSupprEquipe(equipe) {
                this.dialogue_confirmation.texte = equipe.nom
                this.dialogue_confirmation.id_equipe = equipe.id
                this.openDialogueConfirmation = true
            },
            onClickUpdateEquipe() {
                this.chargement_update = true
                axios.post('api/centre/equipes/update', { 
                        sans_equipe: this.liste_sans_equipe,
                        equipes: this.listes_equipes,
                        id_membre: this.$store.getters.membreCourant.id,
					})
                    .then((reponse) => {
                        this.snackbar('success',reponse.data.resultat);
                        this.$store.dispatch('getEquipes', this.centre_selectionne)
                            .then(() => {
                                this.$store.dispatch('getAnnuaire', this.centre_selectionne).then( () => this.init_listes_equipes())
                            })
                            
                    })
                    .catch((error) => {
                        this.snackbar('error', 'Erreur lors de la sauvegarde');
                    })
                    .then(() => {
						this.chargement_update = false
                    })
            },
            onDrop(dropResult, cle, type) {
                if(dropResult.removedIndex != null || dropResult.addedIndex != null) {
                    // console.log('dropResult', dropResult)
                    // console.log('cle', cle)
                    // console.log('type', type)
                }
                //  on modifie la liste lors du déposé de l'élément
                this.listes_equipes_filtree[cle].membres = this.applicationDrag(this.listes_equipes_filtree[cle].membres, dropResult, type)                
            },
            onDropSans(dropResult, cle, type) {
                if(dropResult.removedIndex != null || dropResult.addedIndex != null) {
                    // console.log('dropResult', dropResult)
                    // console.log('cle', cle)
                    // console.log('type', type)
                }
                //  on modifie la liste lors du déposé de l'élément

               
                this.liste_sans_equipe.membres = this.applicationDrag(this.liste_sans_equipe.membres, dropResult, null)                
            },
            premiereLettreMaj(text){
				return text.charAt(0).toUpperCase();
			},
            snackbar(type,message){
                this.$store.commit('afficheSnackbar', {type: type, message: message});
            },
            validSuppression() {
                this.chargement_confirmation = true
                axios.post('api/centre/equipes/suppr', { 
                        id_membre: this.$store.getters.membreCourant.id,
                        id_equipe: this.dialogue_confirmation.id_equipe
					})
                    .then((reponse) => {
                        this.snackbar('success',reponse.data.resultat);
                        this.$store.dispatch('getEquipes', this.centre_selectionne)
                            .then(() => {
                                this.$store.dispatch('getAnnuaire', this.centre_selectionne)
                            })
                            .then( () => this.init_listes_equipes())
                    })
                    .catch((error) => {
                        this.snackbar('error', 'Erreur lors de la suppression');
                    })
                    .then(() => {
						this.chargement_confirmation = false
						this.annuleConfirmation()
                    })
            }

        },
        validations: {
            dialogue_ajout_equipe: {
                nom: {
                    required,             
                },
                est_spp: {
                    required
                }
            },
        },
        watch: {
			centre_courant() {
				
                //  si pas autorisé à rester sur cette page on le vire
                if(!this.acces_autorise('acces gestion equipes')) {
                    this.$router.push('/')
                }
                else {
                    this.$store.dispatch('getAnnuaire', this.$store.getters.membreCourant.centre.id)
                    .then(() => {
                        this.$store.dispatch('getEquipes', this.$store.getters.membreCourant.centre.id).then( () => this.init_listes_equipes())
                    })
                }
			    
            },
            filtre_equipe: {
				handler(val) {
                    let  liste_complete = this.liste_sans_equipe.membres
                    if(val == 'spv') {
                        this.liste_sans_equipe_filtree.membres = liste_complete.filter(membre => membre.statut == 0)
                    }
                    else if(val == 'spp') {
                        this.liste_sans_equipe_filtree.membres = liste_complete.filter(membre => membre.statut == 1)
                    }
                    else{
                        this.liste_sans_equipe_filtree.membres = liste_complete
                    }
				},
				deep: true
				
            },
            liste_sans_equipe: {
                handler(val) {
                    let  liste_complete = this.liste_sans_equipe.membres
                    if(this.filtre_equipe == 'spv') {
                        this.liste_sans_equipe_filtree.membres = liste_complete.filter(membre => membre.statut == 0)
                    }
                    else if(this.filtre_equipe == 'spp') {
                        this.liste_sans_equipe_filtree.membres = liste_complete.filter(membre => membre.statut == 1)
                    }
                    else{
                        this.liste_sans_equipe_filtree.membres = liste_complete
                    }
                },
                deep: true
            }
		}
  	}
</script>

<style scoped>

.zone_equipes {
    overflow-y: auto;
    max-height: calc(100vh - 190px);
}

.zone_sans_equipe {
    overflow-y: auto;
    max-height: calc(100vh - 190px);
}

</style>

